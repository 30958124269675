// 首页图片
<template>
  <body>
    <el-card>
      <el-table :data="tableData" style="width: 100%" class="box-table">
        <el-table-column prop="picurl" label="图片">
          <template #default="scope">
            <img :src="scope.row.picurl" height="200" style="display: center" />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="picurl" label="图片"> </el-table-column> -->
        <el-table-column label="操作" width="250px">
          <template #default="scope">
            <el-button
              type="text"
              style="font-size: 15px"
              @click="editRow(scope.$index)"
              >编辑</el-button
            >
            <el-button
              @click="deleteRow(scope.row.picid)"
              type="text"
              style="font-size: 15px"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" class="addbutton" @click="addbutton"
        >添加</el-button
      >
      <!-- <el-button class="addbutton" type="primary">添加</el-button> -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>
    <!-- 新建新闻/修改新闻 -->
    <el-dialog title="首页图片" v-model="pictureVisible" width="55%">
      <el-form ref="pictureScope" :model="pictureForm">
        <el-form-item label="文件" prop="url">
          <el-upload
            id="uploadImage"
            name="file"
            action="https://imct.oss-cn-beijing.aliyuncs.com"
            :handle-change="handleChange"
            :handle-remove="handleRemove"
            :on-remove="onRemove"
            :file-list="fileList"
            :http-request="toUploadfile"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
          <span class="tiptext" v-show="fileList == ''">未上传文件 </span>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="pictureVisible = false"
            >取 消</el-button
          >
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
import { uploadToAli } from "@/utils/alioss.js";
export default {
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      pictureForm: {
        picname: "",
        picurl: "",
      },
      picId: 0,
      pictureVisible: false,
      fileList: [],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    handleChange(file, fileList) {
      // console.log("这是file", file);
      // console.log("这是fileList", fileList);
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    //上传文件
    async toUploadfile(file) {
      this.fileList = [];
      var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
      // this.name = file.file.name
      var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
      // this.uploadUrl = uploadUrl
      await uploadToAli(storeAs, file.file);
      const fileObj = {
        name: file.file.name,
        url: uploadUrl,
      };
      this.fileList.push(fileObj);
    },
    handleRemove(file) {
      if (file && file.status === "success") {
        this.$axios.delete("accessory/one/" + file.response.id).then((resp) => {
          if (resp.status == 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
      }
    },
    //删除文件
    onRemove(file, fileList) {
      this.fileList = [];
    },

    async getTableData() {
      await this.$axios
        .get("/pics/GetHomepic", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //添加新闻
    addbutton() {
      this.picId = "";
      (this.fileList = []),
        (this.pictureForm = {
          picname: "",
          picurl: "",
        });
      this.pictureVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.picId === "") {
        const response = await this.$axios({
          method: "post",
          url: "/pics/InputPic",
          data: {
            picname: this.fileList[0].name,
            picurl: this.fileList[0].url,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.pictureVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        const response = await this.$axios({
          method: "put",
          url: "/pics/PutPic",
          data: {
            picid: this.picId,
            picname: this.fileList[0].name,
            picurl: this.fileList[0].url,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.pictureVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    //修改服务
    editRow(index) {
      let data = this.tableData[index];
      this.picId = data.picid;
      this.fileList = [];
      const fileObj = {
        name: data.picname,
        url: data.picurl,
      };
      this.fileList.push(fileObj);
      this.pictureForm = {
        picname: data.name,
        picurl: data.url,
      };
      this.pictureVisible = true;
    },

    // //添加图片
    // async addbutton(file) {
    //   // console.log(file);
    //   var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
    //   var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
    //   await uploadToAli(storeAs, file.file);
    //   this.picname = file.file.name;
    //   this.picurl = uploadUrl;
    //   const response = await this.$axios({
    //     method: "post",
    //     url: "/pics/InputPic",
    //     data: {
    //       picname: this.picname,
    //       picurl: this.picurl,
    //     },
    //     dataType: "json",
    //   });
    //   if (response.data.status === "OK") {
    //     this.$message.success("添加成功");
    //     this.getTableData();
    //     scrollBy(0, -top);
    //   } else {
    //     this.$message.error("添加失败");
    //     // location.reload();
    //   }
    // },
    // //编辑图片
    // // async editRow() {

    // //   this.toEditRow();
    // // },
    // async toEditRow(file) {
    //   var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
    //   var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
    //   await uploadToAli(storeAs, file.file);
    //   this.picname = file.file.name;
    //   this.picurl = uploadUrl;
    //   const response = await this.$axios({
    //     method: "put",
    //     url: "/pics/PutPic",
    //     data: {
    //       picname: this.picname,
    //       picid: id,
    //       picurl: this.picurl,
    //     },
    //     dataType: "json",
    //   });
    //   if (response.data.status == "OK") {
    //     this.$message.success("编辑成功");
    //     this.getTableData();
    //   } else {
    //     this.$message.error("编辑失败");
    //   }
    // },
    //删除图片
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/pics/DeletPic",
          method: "delete",
          data: {
            picid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
.tiptext {
  font-size: 10px;
  color: rgb(125, 127, 129);
  margin-left: 50px;
}
</style>