// 联系方式
<template>
  <body>
    <el-card>
      <el-table :data="contactData" style="width: 100%" class="box-table">
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              @click="deleteRow(scope.row.contactid)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >删除</el-button
            >
            <el-button
              @click="editRow(scope.$index)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button class="addbutton" type="primary" @click="addbutton"
        >添 加</el-button
      >
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>
    <!-- 新建/修改联系方式 -->
    <el-dialog title="联系方式" v-model="contactVisible" width="55%">
      <el-form ref="legalScope" :model="contactForm">
        <el-form-item label="标题" prop="title">
          <el-input style="width: 70%" clearable v-model="contactForm.title">
          </el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input
            style="width: 70%"
            clearable
            v-model="contactForm.address"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮编" prop="postal">
          <el-input
            style="width: 70%"
            clearable
            v-model="contactForm.postal"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            style="width: 70%"
            clearable
            v-model="contactForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信" prop="wchat">
          <el-input
            style="width: 70%"
            clearable
            v-model="contactForm.wchat"
          ></el-input>
        </el-form-item>
        <el-form-item label="QQ" prop="qq" style="margin-left: 6px">
          <el-input
            style="width: 70%"
            clearable
            v-model="contactForm.qq"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
          <el-input
            style="width: 70%"
            clearable
            v-model="contactForm.email"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="地图" prop="maps" class="amap-page-container"> -->
        <!-- <el-amap
            ref="map"
            vid="amapDemo"
            :amap-manager="amapManager"
            :center="center"
            :zoom="zoom"
            :events="events"
            class="amap-demo"
          >
          </el-amap> -->

        <!-- <baidu-map class="bm-view" v-model="contactForm.maps"> </baidu-map> -->
        <!-- <el-input
            style="width: 540px"
            clearable
            v-model="contactForm.maps"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="contactVisible = false"
            >取 消</el-button
          >
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
// import { AMapManager } from "vue-amap";
// import BaiduMap from "..../node_modules/vue-baidu-map/components/map/Map.vue";
// let amapManager = new AMapManager();
export default {
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      contactData: [],
      contactForm: {},
      contactVisible: false,
      contactId: "",
      // amapManager,
      // zoom: 12,
      // center: [120.19, 30.26],
      // events: {
      //   init: (o) => {
      //     o.getCity((result) => {
      //       console.log(result);
      //     });
      //   },
      //   click: (e) => {
      //     console.log(e);
      //   },
      // },
    };
  },
  // components: {
  //   BaiduMap, //指定组件模板
  // },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    async getTableData() {
      await this.$axios
        .get("/contacts/GetContactAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.contactData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取单行详情
    async getDatabyRow(id) {
      await this.$axios
        .get("/contacts/GetContact", {
          params: {
            contactid: id,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            let data = response.data;
            this.contactForm = {
              title: data.title,
              address: data.address,
              postal: data.postal,
              phone: data.phone,
              wchat: data.wchat,
              qq: data.qq,
              email: data.email,

              // maps: data.maps,
            };
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //添加联系方式
    addbutton() {
      this.contactId = "";
      this.contactForm = {
        title: "",
        address: "",
        postal: "",
        phone: "",
        wchat: "",
        qq: "",
        email: "",
        // maps: "",
      };
      this.contactVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.contactId === "") {
        const response = await this.$axios({
          method: "post",
          url: "/contacts/InputContact",
          data: {
            title: this.contactForm.title,
            address: this.contactForm.address,
            postal: this.contactForm.postal,
            phone: this.contactForm.phone,
            wchat: this.contactForm.wchat,
            qq: this.contactForm.qq,
            email: this.contactForm.email,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.contactVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        const response = await this.$axios({
          method: "put",
          url: "/contacts/PutContact",
          data: {
            contactid: this.contactId,
            title: this.contactForm.title,
            address: this.contactForm.address,
            postal: this.contactForm.postal,
            phone: this.contactForm.phone,
            wchat: this.contactForm.wchat,
            qq: this.contactForm.qq,
            email: this.contactForm.email,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.contactVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    //修改联系方式
    editRow(index) {
      let data = this.contactData[index];
      this.contactId = data.contactid;
      this.getDatabyRow(this.contactId);
      this.contactVisible = true;
    },
    //删除联系方式
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/contacts/DeletContact",
          method: "delete",
          data: {
            contactid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
.bm-view {
  width: 100%;
  height: 300px;
}
/* .amap-page-container {
  width: 100%;
  height: 400px;
} */
</style>