// 执行董事
<template>
  <el-container>
    <el-main>
      <el-card class="box-card">
        <el-form
          :model="execForm"
          label-width="150px"
          class="form"
          style="margin-top: 50px"
        >
          <el-form-item label="姓名" prop="director">
            <el-input
              style="width: 540px"
              v-model="execForm.director"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片" prop="picurl">
            <el-upload
              id="uploadImage"
              name="file"
              action="https://imct.oss-cn-beijing.aliyuncs.com"
              :handle-remove="handleRemove"
              :on-remove="onRemove"
              :handle-change="handleChange"
              :file-list="fileList"
              :http-request="toUploadfile"
            >
              <el-button type="primary">选择文件</el-button>
            </el-upload>
            <span class="tiptext" v-show="fileList == ''">未上传文件 </span>
          </el-form-item>
          <el-form-item label="详情" prop="name">
            <TEditor7
              ref="editor"
              style="width: 600px; height: 500px"
              :auto-height="true"
              v-model="execForm.introduction"
              @update="updateData($event)"
              width="100%"
            />
          </el-form-item>
          <el-button class="addbutton" type="primary" @click="addbutton"
            >确定</el-button
          >
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import { uploadToAli } from "@/utils/alioss.js";
import TEditor7 from "@/components/Tinymce/Tinymce.vue";
export default {
  components: { TEditor7 },
  name: "upload",
  data() {
    return {
      id: "",
      fileList: [],
      execForm: {},
    };
  },
  mounted() {
    this.getTableDataEdit();
  },
  activated() {
    this.getTableDataEdit();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    updateData(e) {
      this.execForm.introduction = e;
    },
    addbutton() {
      this.putTableData();
    },

    handleChange(file, fileList) {
      // console.log("这是file", file);
      // console.log("这是fileList", fileList);
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    //上传文件
    async toUploadfile(file) {
      this.fileList = [];
      var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
      // this.name = file.file.name
      var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
      // this.uploadUrl = uploadUrl
      await uploadToAli(storeAs, file.file);
      const fileObj = {
        name: file.file.name,
        url: uploadUrl,
      };
      this.fileList.push(fileObj);
    },
    handleRemove(file) {
      if (file && file.status === "success") {
        this.$axios.delete("accessory/one/" + file.response.id).then((resp) => {
          if (resp.status == 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
      }
    },
    //删除文件
    onRemove(file, fileList) {
      this.fileList = [];
    },
    async getTableDataEdit() {
      await this.$axios
        .get("/directors/GetDirector", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.execForm = { ...response.data };
          this.id = response.data.directorid;
          if (this.execForm.picname !== "") {
            this.fileList = [];
            const fileObj = {
              name: this.execForm.picname,
              url: this.execForm.picurl,
            };
            this.fileList.push(fileObj);
          }
        });
    },
    //将填报的数据发给后端
    async putTableData() {
      const response = await this.$axios({
        method: "put",
        url: "/directors/PutDirector",
        data: {
          directorid: this.id,
          director: this.execForm.director,
          picname: this.fileList[0].name,
          picurl: this.fileList[0].url,
          introduction: this.execForm.introduction,
        },
        dataType: "json",
      });
      if (response.data.status == "OK") {
        this.$message.success("修改成功");
        this.getTableDataEdit();
      } else {
        this.$message.error("修改失败");
      }
    },
  },
};
</script>

<style scoped>
.el-form .el-form-item /deep/ .el-form-item__label {
  width: 200px !important;
  font-size: 18px;
}
.addbutton {
  margin-top: -10px;
  margin-left: 85%;
}
.tiptext {
  font-size: 10px;
  color: rgb(125, 127, 129);
  margin-left: 50px;
}
</style>