<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Main",
};
</script>

<style scoped>
.main {
  /* padding: 10px; */
  height: 100%;
  overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
  /* background-color: red; */
}
</style>