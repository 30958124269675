// 联系方式子页
<template>
  <body>
    <el-card>
      <el-table :data="tableData" style="width: 100%" class="box-table">
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              @click="deleteRow(scope.row.contactid)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >删除</el-button
            >
            <el-button
              @click="editRow(scope.$index)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button class="addbutton" type="primary" @click="addbutton"
        >添 加</el-button
      >
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>

    <!-- 新建新闻/修改新闻 -->
    <el-dialog title="新建法律支持" v-model="legalVisible" width="55%">
      <el-form ref="legalScope" :model="legalForm">
        <el-form-item label="标题" prop="name">
          <el-input style="width: 70%" v-model="legalForm.name" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="详情" prop="url">
          <el-input
            style="width: 70%"
            type="textarea"
            :rows="4"
            v-model="legalForm.url"
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="legalVisible = false"
            >取 消</el-button
          >
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [], //存放table数据
      legalForm: {
        name: "",
        url: "",
      },
      legalVisible: false,
      legalId: "",
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    async getTableData() {
      await this.$axios
        .get("/laws/GetLawAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //添加新闻
    addbutton() {
      this.legalId = "";
      this.legalForm = {
        name: "",
        url: "",
      };
      this.legalVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.legalId === "") {
        const response = await this.$axios({
          method: "post",
          url: "/laws/InputLaw",
          data: {
            name: this.legalForm.name,
            url: this.legalForm.url,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.legalVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        const response = await this.$axios({
          method: "put",
          url: "/laws/Putlaw",
          data: {
            lawid: this.legalId,
            name: this.legalForm.name,
            url: this.legalForm.url,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.legalVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    //修改服务
    editRow(index) {
      let data = this.tableData[index];
      this.legalId = data.lawid;
      this.legalForm = {
        name: data.name,
        url: data.url,
      };
      this.legalVisible = true;
    },
    //删除服务
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/laws/DeletLaw",
          method: "delete",
          data: {
            lawid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
</style>